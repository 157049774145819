import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    name: "notFound",
    path: "/:path(.*)+",
    redirect: {
      name: "home",
    },
  },
  {
    name: "home",
    path: "/home",
    component: () => import("./view/home"),
    meta: {
      title: "home",
    },
  },
  {
    name: "success",
    path: "/success",
    component: () => import("./view/success"),
    meta: {
      title: "success",
    },
  },
  {
    name: "result",
    path: "/result",
    component: () => import("./view/result"),
    meta: {
      title: "result",
    },
  },
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };
