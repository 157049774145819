<template>
  <div id="app">
    <router-view />
  </div>
</template>


<script>
export default {
  watch: {
    // $route(to) {
    //   if (to.name) {
    //     localStorage.setItem("menuactive", to.name);
    //   }
    // },
  },
};
</script>
<style>
</style>
